<template>
  <div :class="`${prefixCls}-person`" id="aochenInvite">
    <Separate title="招贤纳士" desc="INVITE WISDOM VALOR" class="wow animate__fadeInUp" data-wow-duration="1s" />
    <div
        :class="`${prefixCls}-person-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
        <div class="head">
            <div class="head-item" v-for="item in headList" :key="item">{{ item }}</div>
        </div>
      <div class="dynamics-card" v-for="item in infoList" :key="item.id">
          <div class="dynamics-card-header">
              <div class="value-item">{{ item.name }}</div>
              <div class="value-item">{{ item.address }}</div>
              <div class="value-item">{{ item.zprs }}</div>
              <div class="value-item">{{ item.gzrx }}</div>
          </div>
          <div class="dynamics-card-content" v-html="item.content">
          </div>
          <button class="btn" @click="handleGo2(item.linkUrl)">投递简历</button>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import { siteRecruitmentPage } from '@/api'
import { validatenull } from '@/utils/validate'
import moment from 'moment/moment'
export default {
  name: 'Invite',
  filters: {
    formatDate (val) {
      if (validatenull(val)) return '--'
      return moment(val).format('yyyy-MM-DD')
    }
  },
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      infoList: [],
      headList: ['职位名称', '工作地点', '招聘人数', '工作年限']
    }
  },
  mixins: [WowMixin],
  props: {
    introductionData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    Separate
  },
  created () {
    this.siteRecruitmentPage()
  },
  methods: {
    siteRecruitmentPage () {
      siteRecruitmentPage().then(({ data }) => {
        this.infoList = data.data.rows
      })
    },
    handleClick (data) {
      this.$router.push({ path: '/detail', query: { id: data.id } })
    },
    handleGo2 (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-person;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $content-width;
  padding-bottom: 50px;
  overflow: hidden;
  &-content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    .head {
      display: flex;
      align-items: center;
      .head-item {
        width: 25%;
        height: 64px;
        line-height: 64px;
        font-size: 20px;
        font-weight: bold;
        color: #1E2A48;
        box-sizing: border-box;
        background: #F7F9FA;
        text-align: left;
        padding: 0 15px;
      }
    }
    .dynamics-card {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      transition: all .3s;
      margin-bottom: 20px;
      border-bottom: 2px solid #EDEDED;
      &:last-child {
        margin-bottom: 0;
      }
      &-header {
        display: flex;
        align-items: center;
        .value-item {
          width: 25%;
          height: 104px;
          line-height: 104px;
          font-size: 16px;
          font-weight: bold;
          color: #1E2A48;
          box-sizing: border-box;
          text-align: left;
          padding: 0 15px;
          font-family: arial !important;
          font-size: 16px !important;
          color: #191818 !important;
          line-height: 40px !important;
          font-weight: lighter !important;
        }
      }
      &-content {
        padding: 0 15px 40px;
        font-family: arial !important;
        font-size: 16px !important;
        color: #191818 !important;
        line-height: 40px !important;
        font-weight: lighter !important;
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 86px;
        height: 30px;
        text-align: center;
        border: 1px solid #B81D23;
        font-size: 14px;
        color: #B81D23;
        position: absolute;
        bottom: 15px;
        right: 24px;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
